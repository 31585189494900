<template>
  <!--新增对话框-->
  <el-dialog title="详情" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
    <el-divider>商品信息</el-divider>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            商品名称：
          </el-col>
          <el-col :span="18">
            {{ detail.exchangeGoodsName || '' }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            商品分类:：
          </el-col>
          <el-col :span="18">
            <span v-if="detail.exchangeGoodsUse == 1">两端</span>
            <span v-if="detail.exchangeGoodsUse == 2">货主端</span>
            <span v-if="detail.exchangeGoodsUse == 3">司机端</span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            所需积分：
          </el-col>
          <el-col :span="18">
            {{ detail.requiredPoints }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            实际价格：
          </el-col>
          <el-col :span="18">
            {{ detail.realValue }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">

      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            商品类型：
          </el-col>
          <el-col :span="18">
            <span v-if="detail.exchangeGoodsType == 1">虚拟商品</span>
            <span v-if="detail.exchangeGoodsType == 2">实体商品</span>
            <span v-if="detail.exchangeGoodsType == 3">优惠券</span>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            商品数量：
          </el-col>
          <el-col :span="18">
            {{ detail.exchangeGoodsCount|| '1' }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" v-if="detail.exchangeGoodsType == 3">
        <el-row :gutter="20">
          <el-col :span="6">
            优惠券：
          </el-col>
          <el-col :span="18">
            {{ detail.couponsName }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            商品图片：
          </el-col>
          <el-col :span="18">
            <el-image style="width: 100px; height: 100px" :src="detail.exchangeGoodsPic" :preview-src-list="srcList" fit="scale-down">
            </el-image>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider>兑换人信息</el-divider>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            收货人姓名：
          </el-col>
          <el-col :span="18">
            {{ detail.name || '' }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            用户昵称：
          </el-col>
          <el-col :span="18">
            {{ detail.nickName }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            收货人电话：
          </el-col>
          <el-col :span="18">
            {{ detail.phone }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            详细地址：
          </el-col>
          <el-col :span="18">
            {{ detail.address }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            兑换时间：
          </el-col>
          <el-col :span="18">
            {{ detail.createTime }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider>物流信息</el-divider>
    <el-row :gutter="20">
<!--      <el-col :span="12">-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :span="6">-->
<!--            物流公司:-->
<!--          </el-col>-->
<!--          <el-col :span="18">-->
<!--            {{ detail.shipmentsLogisticName }}-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-col>-->
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            物流单号：
          </el-col>
          <el-col :span="18">
            {{ detail.shipmentsLogisticCode || '' }}
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row :gutter="20">
          <el-col :span="6">
            发货时间：
          </el-col>
          <el-col :span="18">
            {{ detail.sentTime || '' }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
      imgVisible: false,
      imageUrl: null,
      detail: {},
      dialogVisible: false,
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    format(date, fmt) {
      var o = {
        "M+": date.getMonth() + 1,                 //月份
        "d+": date.getDate(),                    //日
        "h+": date.getHours(),                   //小时
        "m+": date.getMinutes(),                 //分
        "s+": date.getSeconds(),                 //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()             //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    },

    init(id) {
      this.dialogVisible = true;
      this.$axios.post('/admin/exchangebill/getExchangeBillInfo', { exchangeBillId: id }).then(res => {
        this.detail = JSON.parse(JSON.stringify(res.data))
        if (this.detail.exchangeGoodsPic) {
          this.srcList = this.detail.exchangeGoodsPic.split(',')
        }
        console.log( this.srcList )
        this.detail.createdTime = this.format(new Date(this.detail.createdTime), 'yyyy-MM-dd hh:mm:ss')
        if (this.detail.sentTime) {
          this.detail.sentTime = this.format(new Date(this.detail.sentTime), 'yyyy-MM-dd hh:mm:ss')
        }

      })
    },
  }
}
</script>

<style scoped>
.el-col {
  margin-bottom: 10px;
}
</style>
